import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./App.css";
import { RetellWebClient } from "retell-client-js-sdk";
import { useLocation } from "react-router-dom";


interface RegisterCallResponse {
  callId?: string;
  sampleRate: number;
}

const webClient = new RetellWebClient();

const App = () => {
  const [isCalling, setIsCalling] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const agentId = queryParams.get("agentId") || "";
  const agentType = queryParams.get("agent_type") || "";
  const teamsMeetingId = queryParams.get("teams_meeting_id") || "";

  // Reuse the same AudioContext
  const audioContext = useMemo(
    () => new (window.AudioContext || (window as any).webkitAudioContext)(),
    []
  );
  console.log(teamsMeetingId,agentType,agentId,"query data")
  const playAudio = useCallback(
    (audioData: Uint8Array) => {
      console.log("Received audio data:", audioData);

      const audioBuffer = audioData.buffer.slice(
        audioData.byteOffset,
        audioData.byteOffset + audioData.byteLength
      );

      audioContext
        .decodeAudioData(audioBuffer)
        .then((decodedData) => {
          console.log("Audio data decoded successfully");
          const source = audioContext.createBufferSource();
          source.buffer = decodedData;
          source.connect(audioContext.destination);
          source.start(0);
        })
        .catch((error) => {
          console.error("Error decoding audio data", error);
        });
    },
    [audioContext]
  );

  useEffect(() => {
    // Setup event listeners
    webClient.on("conversationStarted", () => {
      console.log("conversationStarted");
    });

    webClient.on("agent_start_talking", () => {
      console.log("agent_start_talking");
    });

    webClient.on("agent_stop_talking", () => {
      console.log("agent_stop_talking");
    });

    webClient.on("audio", (audio: Uint8Array) => {
      console.log("There is audio", audio);
      playAudio(audio);
    });

    webClient.on("conversationEnded", ({ code, reason }) => {
      console.log("Closed with code:", code, ", reason:", reason);
      setIsCalling(false); // Update button to "Start" when conversation ends
    });

    webClient.on("error", (error) => {
      console.error("An error occurred:", error);
      setIsCalling(false); // Update button to "Start" in case of error
    });

    webClient.on("update", (update) => {
      console.log("update", update);
    });
  }, [playAudio]);

  const toggleConversation = async () => {
    if (isCalling) {
      webClient.stopConversation();
    } else {
      try {
        const registerCallResponse = await registerCall(agentId, agentType,teamsMeetingId);

        console.log(registerCallResponse, "response");
        if (registerCallResponse.callId) {
          // Adjust the audio context sample rate if needed
          if (audioContext.sampleRate !== registerCallResponse.sampleRate) {
            console.warn(
              `Expected sample rate: ${registerCallResponse.sampleRate}, but got: ${audioContext.sampleRate}`
            );
          }

          webClient
            .startConversation({
              callId: registerCallResponse.callId,
              sampleRate: registerCallResponse.sampleRate, // Use the correct sample rate
              enableUpdate: true,
            })
            .then(() => {
              setIsCalling(true); // Update button to "Stop" when conversation starts
            })
            .catch((error) => {
              console.error("Failed to start conversation:", error);
            });
        }
      } catch (error) {
        console.error("Failed to register call:", error);
      }
    }
  };

  async function registerCall(
    agentId: string,
    agentType: string,
    teamsMeetingId:string
  ): Promise<RegisterCallResponse> {
    try {
      // Replace with your server URL
      const response = await fetch(
        "https://retell.backend.docsightai.com/retell-query/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ agentId: agentId, agent_type: agentType,teams_meeting_id:teamsMeetingId}),
        }
      );
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data: RegisterCallResponse = await response.json();
      console.log(data, "data");
      return data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.message);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={toggleConversation}>
          {isCalling ? "Stop" : "Start"}
        </button>
      </header>
    </div>
  );
};

export default App;
